import React from 'react';
import Login from '../components/Login'
const LoginPage = () =>{
    return(
        <div id="loginform">
        <Login />
      </div>
    )
}

export default LoginPage;